import React, { FC } from 'react';

import { RequestStatusType } from '@app/types';
import { HistoryEventTypesEnum } from '@app/types/history';
import { Space, Typography } from 'antd';
import { RequestStatus } from '@app/components/request_status';
import { getHistoryTitle } from '@app/components/updates_label/updates_history/utils';

interface HistoryTitleProps {
  status: RequestStatusType;
  eventType: HistoryEventTypesEnum;
}

export const HistoryTitle: FC<HistoryTitleProps> = ({ status, eventType }) => {
  return (
    <Typography.Title level={4} style={{ maxWidth: '90%', minWidth: '350px' }} className={'fw-semibold'}>
      <Space>
        {getHistoryTitle(eventType)}
        {eventType === HistoryEventTypesEnum.RefundStatusTransition && <RequestStatus status={status} />}
      </Space>
    </Typography.Title>
  );
};
