import axiosInstance from '@app/config/api/config';
import { RequestTransitionCommands, VerifyReceipt } from '@app/types';

export async function createRefundRequest(payload: object) {
  const res = await axiosInstance.post('refund_requests', payload);
  return res.data;
}

export async function getRefundRequests(params) {
  const res = await axiosInstance.get('refund_requests', { params });
  return res.data;
}

export async function getDraftRefundRequests(params) {
  const res = await axiosInstance.get('refund_requests/drafts', { params });
  return res.data;
}

export async function getCancelledRefundRequests(params) {
  const res = await axiosInstance.get('refund_requests/cancelled', { params });
  return res.data;
}

export async function getRefundRequest(id: string) {
  const res = await axiosInstance.get(`refund_requests/${encodeURIComponent(id)}`);
  return res.data;
}

export async function submitRefundRequest(id: string, eventType: RequestTransitionCommands | string) {
  const res = await axiosInstance.post(`refund_requests/${encodeURIComponent(id)}/transition`, { event: eventType });
  return res.data;
}

export async function updateRefundRequest(id: string, payload: any) {
  const res = await axiosInstance.put(`refund_requests/${encodeURIComponent(id)}`, payload);
  return res.data;
}

export async function deleteRefundRequest(id: string) {
  const res = await axiosInstance.delete(`refund_requests/${encodeURIComponent(id)}`);
  return res.data;
}

export async function getAllowedEvents(id: string) {
  const res = await axiosInstance.get(`refund_requests/${encodeURIComponent(id)}/transition/actions`);
  return res.data;
}

export async function getSelectedReceipts(id: string, selectedReceipts: string) {
  const res = await axiosInstance.get(`refund_requests/${encodeURIComponent(id)}/export/receipts`, {
    params: {
      receipt_ids: selectedReceipts,
    },
  });
  return res.data;
}

export async function importReceipts(id: string, file: any) {
  const res = await axiosInstance.post(`refund_requests/${encodeURIComponent(id)}/import/receipts`, file);
  return res.data;
}

export async function getReceiptsVerifications(id: string) {
  const res = await axiosInstance.get(`refund_requests/${encodeURIComponent(id)}/receipts_verifications`);
  return res.data;
}

export async function changeVerificationsReceipts(id: string, payload: Array<VerifyReceipt>) {
  const res = await axiosInstance.post(`refund_requests/${encodeURIComponent(id)}/receipts_verifications`, payload);
  return res.data;
}

export async function getReceiptCategories(params = {}) {
  const res = await axiosInstance.get('receipt_categories');
  return res.data;
}

export async function getRequestStatuses(params = {}) {
  const res = await axiosInstance.get('statuses');
  return res.data;
}
