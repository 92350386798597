import React, { FC, useContext, useMemo } from 'react';
import { Divider, Drawer, Typography } from 'antd';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';

import { i18n } from '@app/translations/i18n.config';
import { LanguageContext } from '@app/global_provider';
import { getHistory, getRequestStatuses } from '@app/api';
import { RQContent } from '@app/components/rq_content';
import { HistoryItem } from '@app/components/updates_label/updates_history/history-item';
import { DATE_FORMAT } from '@app/config/constants';
import { formatDateForDivider } from '@app/components/updates_label/updates_history/utils';
import { Tools } from '@app/utils/tools';
import { RequestStatusType } from '@app/types';

interface HistoryDrawerProps {
  isDrawerOpen: boolean;
  onClose: () => void;
  requestId: string;
}

export const HistoryDrawer: FC<HistoryDrawerProps> = ({ isDrawerOpen, onClose, requestId }) => {
  const { isRtl } = useContext<any>(LanguageContext);

  const { status: historyStatus, data: historyData } = useQuery({
    queryKey: ['updates_history', requestId],
    queryFn: () => getHistory(requestId),
  });

  const { data: statuses, isLoading } = useQuery({
    queryKey: ['request_statuses'],
    queryFn: () => getRequestStatuses(),
  });

  const groupedHistory = useMemo(() => {
    if (Tools.isPresent(historyData)) {
      return historyData.reduce((acc: Record<string, any[]>, item) => {
        const date = dayjs(item.createdAt).startOf('day').format(DATE_FORMAT);
        acc[date] = acc[date] || [];
        acc[date].push(item);
        return acc;
      }, {});
    } else {
      return {};
    }
  }, [historyData]);

  const statusesHash = useMemo(() => {
    if (Tools.isPresent(statuses)) {
      return Tools.arrayToObject<RequestStatusType>(statuses, 'key');
    } else {
      return {};
    }
  }, [statuses]);

  return (
    <Drawer
      width={450}
      title={<Typography.Title level={3}>{i18n.t('updatesHistory.title')}</Typography.Title>}
      placement={isRtl ? 'left' : 'right'}
      open={isDrawerOpen}
      onClose={onClose}
    >
      <RQContent status={historyStatus}>
        <>
          {Object.keys(groupedHistory)
            .sort((a, b) => dayjs(b).diff(dayjs(a)))
            .map((date) => (
              <div key={date}>
                <Divider className={'mt-0 mb-2'}>
                  <Typography.Title level={5}>{formatDateForDivider(date)}</Typography.Title>
                </Divider>

                {groupedHistory[date].map((item) => (
                  <HistoryItem key={item.id} historyItemData={item} statusesHash={statusesHash} />
                ))}
              </div>
            ))}
        </>
      </RQContent>
    </Drawer>
  );
};
