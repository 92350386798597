import React from 'react';
import { Button, Card, Space, Typography } from 'antd';

import { i18n } from '@app/translations/i18n.config';

export const FilterSection = ({ title, resetHandler, children, hidden }) => (
  <Card
    className="mb-3"
    size="small"
    bordered={false}
    title={
      <Space className="w-100 justify-content-between">
        <Typography.Title level={3} className="mt-1">
          {title}
        </Typography.Title>
        <Button
          hidden={hidden}
          className="p-0"
          style={{ minWidth: 'initial' }}
          size="small"
          type="dashed"
          onClick={resetHandler}
        >
          {i18n.t('searchFilterBar.reset')}
        </Button>
      </Space>
    }
  >
    {children}
  </Card>
);
