import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row, Space, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useMutation, useQuery } from 'react-query';

import { i18n } from '@app/translations/i18n.config';
import { EmployeeTable } from '@app/components/tables/employee_table';
import { ReceiptsTable } from '@app/components/tables/receipts_table';
import { ActionButton } from '@app/components/action_button';
import { Comments } from '@app/components/comments';
import { Attachments } from '@app/components/attachments';
import { getRefundRequest, updateRefundRequest } from '@app/api';
import { RQContent } from '@app/components/rq_content';
import { RequestStatus } from '@app/components/request_status';
import { useAuth } from '@app/auth/auth-context';
import { isChangeCategoryForAuditorAllowed, isEditRequestActionsAllowed } from '@app/utils/permissions/permissions';
import { Tools } from '@app/utils/tools';
import { UpdatesLabel } from '@app/components/updates_label';
import { PaymentsTable } from '@app/components/tables/payments_table';
import { UserRolesEnum } from '@app/types';

export const Request = () => {
  const { id } = useParams();
  const { userRoles, user } = useAuth();

  const [isAttachmentsEmpty, setIsAttachmentsEmpty] = useState(true);

  const isAppAdmin = userRoles.includes(UserRolesEnum.AppAdmin);

  const {
    status: requestStatus,
    data: requestData,
    refetch: refetchRequestData,
  } = useQuery({
    queryKey: ['refund_request'],
    queryFn: () => getRefundRequest(id),
  });

  const { mutate: updateRequestMutate, isLoading: isUpdateLoading } = useMutation({
    mutationFn: (data: any) => updateRefundRequest(id, data),
    onSuccess: async () => {
      refetchRequestData();
    },
  });

  const receiptsChangeHandler = (receipts) => {
    updateRequestMutate({ data: { receipts } });
  };

  const isUserOwner = Tools.isPresent(requestData) && requestData.creatorId === user.sub;
  const isActionsAllowed =
    Tools.isPresent(requestData) && isEditRequestActionsAllowed(userRoles, requestData.status, isUserOwner);

  const isActionsForAuditorAllowed =
    Tools.isPresent(requestData) && isChangeCategoryForAuditorAllowed(userRoles, requestData.status?.key);

  const disableSubmit =
    !requestData?.request?.receipts || requestData?.request?.receipts?.length < 1 || isAttachmentsEmpty;

  return (
    <Content className={'sub-content-white mx-2 mt-2 px-4 py-2'}>
      <RQContent status={[requestStatus]}>
        <Row>
          <Col span={24} className={'mb-4'}>
            <Space className={'w-100 justify-content-between sticky-top z-3 pt-2 bg-white'}>
              <Space size={'large'}>
                <Typography.Title level={1}>
                  {i18n.t('request.title')} {requestData?.requestId}
                </Typography.Title>

                <RequestStatus status={requestData?.status} />
              </Space>

              <ActionButton request={requestData} disableSubmit={disableSubmit} />
            </Space>

            <UpdatesLabel requestId={id} updatedAt={requestData?.updatedAt} />
          </Col>

          <Col span={24}>
            <Row gutter={16}>
              <Col span={18} className={'d-flex vstack align-items-start'}>
                <Space direction={'vertical'} size={'middle'} className={'w-100 light-grey-bg border-radius-large p-2'}>
                  {Tools.isPresent(requestData) && <EmployeeTable requester={requestData.requester || {}} />}

                  {isAppAdmin && <PaymentsTable refundId={id} />}

                  <ReceiptsTable
                    onChange={receiptsChangeHandler}
                    isActionsAllowed={isActionsAllowed}
                    isActionsForAuditorAllowed={isActionsForAuditorAllowed}
                    isDataLoading={isUpdateLoading}
                    totalAmount={requestData?.totalAmount}
                    totalTax={requestData?.totalTax}
                    totalTaxedAmount={requestData?.totalTaxedAmount}
                    data={Tools.isPresent(requestData?.request?.receipts) ? requestData?.request?.receipts : []}
                    requestTitle={`${i18n.t('request.title')} ${requestData?.requestId}`}
                    requestStatus={requestData?.status}
                  />
                </Space>

                <Attachments
                  handleAttachmentsStatus={setIsAttachmentsEmpty}
                  requestId={id}
                  requestNumber={requestData?.requestId || ''}
                  isActionsAllowed={isActionsAllowed}
                />
              </Col>

              <Col span={6}>
                <Comments requestId={id} />
              </Col>
            </Row>
          </Col>
        </Row>
      </RQContent>
    </Content>
  );
};
