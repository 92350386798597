import React, { FC, useEffect, useMemo, useState } from 'react';
import { Table, Input, Typography, Row, Col, Button, Alert } from 'antd';

import { RQContent } from '@app/components/rq_content';
import { Tools } from '@app/utils/tools';
import { i18n } from '@app/translations/i18n.config';

import { ReactComponent as ErrorIcon } from '@app/assets/icons/error.svg';
import { NumericFormat } from 'react-number-format';
import { Safebox } from '@app/types';
import { RQStatus } from '@app/components/rq_content/rq-content';

interface RefundFormProps {
  paymentStatus: RQStatus;
  payment: any;
  onPaymentAdd: (data: any) => void;
  safebox: Safebox;
  data: any;
}

export const RefundForm: FC<RefundFormProps> = ({ paymentStatus, payment, onPaymentAdd, safebox, data }) => {
  const [quantities, setQuantities] = useState(data?.map((item) => ({ [String(item.denomination)]: 0 })));
  const [differenceValue, setDifferenceValue] = useState(0);

  const handleQuantityChange = (value, index, key, quantity) => {
    const newQuantities = [...quantities];
    newQuantities[index][key] = parseInt(value) > quantity ? quantity : parseInt(value) || 0;
    setQuantities(newQuantities);
  };

  const updateQuantities = (combination) => {
    const updatedQuantities = quantities?.map((item) => {
      const [denominationStr] = Object.keys(item);
      const denomination = parseFloat(denominationStr);
      const quantity = combination[denomination] || 0;
      return { [denominationStr]: quantity };
    });

    setQuantities(updatedQuantities);
  };

  useEffect(() => {
    if (Tools.isPresent(payment?.combination)) {
      updateQuantities(payment.combination);
    }
  }, [payment]);

  const columns = [
    {
      title: i18n.t('refund.tableTitle.denomination'),
      dataIndex: 'denomination',
      key: 'denomination',
    },
    {
      title: i18n.t('refund.tableTitle.amount'),
      render: (text, record) => (
        <NumericFormat
          displayType={'text'}
          value={Number(record.denomination) * Number(record.quantity)}
          thousandSeparator=","
        />
      ),
    },
    {
      title: i18n.t('refund.tableTitle.quantity'),
      dataIndex: 'quantity',
      key: 'quantity',
      render: (text, record) => <span>{record.quantity}</span>,
    },
    {
      title: i18n.t('refund.tableTitle.enterQuantity'),
      render: (text, record, index) => (
        <Input
          min={0}
          max={record.quantity}
          value={quantities[index][record.denomination]}
          onChange={(e) => handleQuantityChange(e.target.value, index, record.denomination, record.quantity)}
          style={{ width: 88 }}
        />
      ),
    },
    {
      title: i18n.t('refund.tableTitle.total'),
      dataIndex: 'total',
      key: 'total',
      width: 150,
      render: (text, record, index) => <span>{quantities[index][record.denomination] * record.denomination}</span>,
    },
  ];

  const totalAmount = useMemo(() => {
    if (Tools.isPresent(quantities)) {
      return quantities?.reduce((sum, item) => {
        const [denominationStr, quantity] = Object.entries(item)[0];
        return sum + Number(denominationStr) * Number(quantity);
      }, 0);
    } else {
      return 0;
    }
  }, [quantities]);

  const isTotalDifferAmount = useMemo(() => {
    if (payment?.amount == null) return false;
    const difference = Math.abs(totalAmount - payment.amount);
    setDifferenceValue(totalAmount - payment.amount);
    return difference > 5;
  }, [totalAmount, payment]);

  const createCombination = (quantitiesArr) => {
    return quantitiesArr.reduce((result, item) => {
      const [denomination, quantity] = Object.entries(item)[0];
      if (quantity !== 0) {
        result[denomination] = quantity;
      }
      return result;
    }, {});
  };

  const handleAddPayment = () => {
    const data = {
      safeboxId: safebox?.id,
      amount: totalAmount,
      combination: createCombination(quantities),
    };
    onPaymentAdd(data);
  };

  return (
    <RQContent status={paymentStatus}>
      <>
        <Typography className={'text-dark-violet with-bottom-border fs-6 m-0'}>
          {i18n.t('refund.typeQuantityDescription')}
        </Typography>

        <Table className={'min-padding'} dataSource={data} columns={columns} pagination={false} rowKey="denomination" />

        <Row justify="space-between" className={'mt-2'}>
          <Col style={{ width: 'calc(100% - 200px)' }} className={'ps-3 d-flex flex-row-reverse'}>
            {isTotalDifferAmount && (
              <Alert
                description={i18n.t('messages.error.amountDiffer', { value: differenceValue.toFixed(2) })}
                type="error"
                showIcon
                icon={<ErrorIcon width={20} height={15} />}
              />
            )}
          </Col>
          <Col style={{ maxWidth: 50 }}>
            <Typography className={'fs-6 m-0 text-grey'}>{i18n.t('refund.total')}</Typography>
          </Col>
          <Col style={{ width: 150, padding: '0 8px' }}>
            <Typography className={'fs-6 m-0 d-flex'}>
              <NumericFormat displayType={'text'} value={totalAmount} thousandSeparator="," />
              <Typography className={'fs-6 m-0 text-grey'}>د.إ</Typography>
            </Typography>
          </Col>
        </Row>
        <Row justify="space-between" align={'middle'} className={'mt-4'}>
          <Col>
            <Typography className={'fs-4 m-0 d-flex'}>
              <Typography className={'mb-0 text-grey mx-1'}>{i18n.t('refund.totalRefund')}</Typography>
              <NumericFormat displayType={'text'} value={payment?.amount} thousandSeparator="," />
              <Typography className={'fs-6 mb-0 text-grey mt-1 mx-1'}>د.إ</Typography>
            </Typography>
          </Col>
          <Col>
            <Button
              onClick={() => handleAddPayment()}
              disabled={isTotalDifferAmount}
              style={{ minWidth: 148 }}
              type={'primary'}
            >
              {i18n.t('buttons.getPaid')}
            </Button>
          </Col>
        </Row>
      </>
    </RQContent>
  );
};
