import React, { FC, useContext, useMemo, useState } from 'react';
import { Button, DatePicker, Drawer, Form, Input, Select, Space, Typography } from 'antd';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';

import { FilterSection } from '@app/components/tables/request_table/search_filter_bar/filter-section';
import { getRequestStatuses } from '@app/api';
import { useQueryState } from '@app/hooks/use-query-state';
import { Tools } from '@app/utils/tools';
import { LanguageContext } from '@app/global_provider';
import { i18n } from '@app/translations/i18n.config';
import { DATE_FORMAT } from '@app/config/constants';
import { FilterParamsEnum } from '@app/components/tables/request_table/search_filter_bar/filters.enum';
import { isShowTotalAmountPaid } from '@app/utils/permissions/permissions';
import { useAuth } from '@app/auth/auth-context';
import { RefundRequestStatusesEnum } from '@app/types';
import { getStatusOptionsByRole } from '@app/components/tables/request_table/search_filter_bar/utils';

import { ReactComponent as ArrowIcon } from '@app/assets/icons/arrow-rotate-left.svg';

const { RangePicker } = DatePicker;

interface FilterDrawerProps {
  isDrawerOpen: boolean;
  onClose: () => void;
}

export const FilterDrawer: FC<FilterDrawerProps> = ({ isDrawerOpen, onClose }) => {
  const { isRtl } = useContext<any>(LanguageContext);
  const { userRoles } = useAuth();

  const [urlParams, setUrlParams] = useQueryState();
  const [filterParams, setFilterParams] = useState(() => {
    const { search, ...otherParams } = urlParams;
    return otherParams;
  });

  const { data: statuses, isLoading } = useQuery({
    queryKey: ['request_statuses'],
    queryFn: () => getRequestStatuses(),
  });

  const statusOptions = useMemo(() => {
    const isStatusAllowed = (statusKey) =>
      statusKey !== RefundRequestStatusesEnum.Draft && statusKey !== RefundRequestStatusesEnum.Cancelled;

    if (Tools.isPresent(statuses)) {
      const options = statuses
        ?.filter((s) => isStatusAllowed(s.key))
        .map((s) => ({
          label: s.name,
          value: s.key,
        }));

      return getStatusOptionsByRole(options, userRoles);
    } else {
      return null;
    }
  }, [statuses]);

  const updateUrlParamsHandler = (params) => {
    setUrlParams({ ...urlParams, ...params });
  };

  const resetParamsHandler = (params) => {
    setFilterParams((prev) => {
      const updatedParams = { ...prev };

      const resetParam = (param) => {
        updatedParams[param] = Array.isArray(updatedParams[param]) ? [] : null;
      };

      const keysToReset = params || Object.values(FilterParamsEnum);
      keysToReset.forEach(resetParam);

      return updatedParams;
    });
  };

  const isFilterParamsEmpty = (params) => {
    return Object.values(params).every((p) => Tools.isBlank(p));
  };

  const isApplyParamsDisabled = () => {
    const { search, ...otherParams } = urlParams;
    const isEqual = JSON.stringify(filterParams) === JSON.stringify(otherParams);
    const isEmpty = isFilterParamsEmpty(filterParams) && isFilterParamsEmpty(otherParams);

    return isEqual || isEmpty;
  };

  return (
    <Drawer
      title={
        <Space className={'w-100 justify-content-between'}>
          <Typography.Title className={'my-1'} level={3}>
            {i18n.t('searchFilterBar.drawerTitle')}
          </Typography.Title>
          <Button
            hidden={isFilterParamsEmpty(filterParams)}
            className={isRtl && 'flex-row-reverse'}
            size={'small'}
            type={'dashed'}
            icon={<ArrowIcon className={isRtl && 'me-2 ms-0'} />}
            onClick={() => resetParamsHandler(null)}
          >
            {i18n.t('searchFilterBar.resetAll')}
          </Button>
        </Space>
      }
      placement={isRtl ? 'left' : 'right'}
      open={isDrawerOpen}
      onClose={onClose}
      footer={
        <Button
          type={'primary'}
          disabled={isApplyParamsDisabled()}
          onClick={() => {
            updateUrlParamsHandler(filterParams);
            onClose();
          }}
        >
          {i18n.t('searchFilterBar.buttons.applyFilters')}
        </Button>
      }
    >
      <Form colon={false}>
        <FilterSection
          title={i18n.t('searchFilterBar.date.title')}
          hidden={Tools.isBlank(filterParams.minDate) && Tools.isBlank(filterParams.maxDate)}
          resetHandler={() => resetParamsHandler([FilterParamsEnum.MinDate, FilterParamsEnum.MaxDate])}
        >
          <RangePicker
            className={'w-100'}
            separator={i18n.t('searchFilterBar.from')}
            suffixIcon={i18n.t('searchFilterBar.to')}
            value={[
              (filterParams.minDate ? dayjs(filterParams.minDate) : null) as any,
              (filterParams.maxDate ? dayjs(filterParams.maxDate) : null) as any,
            ]}
            placeholder={[
              i18n.t('searchFilterBar.date.startPlaceholder'),
              i18n.t('searchFilterBar.date.endPlaceholder'),
            ]}
            onChange={([minDate, maxDate]) => {
              setFilterParams({
                ...filterParams,
                minDate: dayjs(minDate.toDate()).format(DATE_FORMAT),
                maxDate: dayjs(maxDate.toDate()).format(DATE_FORMAT),
              });
            }}
            format={DATE_FORMAT}
          />
        </FilterSection>

        <FilterSection
          title={i18n.t('searchFilterBar.statuses.title')}
          hidden={Tools.isBlank(filterParams.statuses)}
          resetHandler={() => resetParamsHandler([FilterParamsEnum.Statuses])}
        >
          <Select
            optionFilterProp={'label'}
            options={statusOptions}
            placeholder="Select Statuses"
            mode="multiple"
            value={Tools.wrapArray(filterParams.statuses)}
            dropdownClassName={'text-upper-case'}
            loading={isLoading}
            className={'w-100 text-upper-case'}
            onChange={(values) => setFilterParams({ ...filterParams, statuses: values })}
          />
        </FilterSection>

        <FilterSection
          title={i18n.t('searchFilterBar.amount.title')}
          hidden={Tools.isBlank(filterParams.minTotalAmount) && Tools.isBlank(filterParams.maxTotalAmount)}
          resetHandler={() => resetParamsHandler([FilterParamsEnum.MinTotalAmount, FilterParamsEnum.MaxTotalAmount])}
        >
          <Space>
            <Form.Item label={<Typography className={'m-0'}>{i18n.t('searchFilterBar.from')}</Typography>}>
              <Input
                type={'number'}
                min={0}
                placeholder={'0'}
                value={parseInt(filterParams?.minTotalAmount)}
                onChange={(e) => setFilterParams({ ...filterParams, minTotalAmount: e.target.value })}
              />
            </Form.Item>
            <Form.Item label={<Typography className={'m-0'}>{i18n.t('searchFilterBar.to')}</Typography>}>
              <Input
                type={'number'}
                min={0}
                placeholder={'1000'}
                value={parseInt(filterParams?.maxTotalAmount)}
                onChange={(e) => setFilterParams({ ...filterParams, maxTotalAmount: e.target.value })}
              />
            </Form.Item>
          </Space>
        </FilterSection>

        {isShowTotalAmountPaid(userRoles) && (
          <FilterSection
            title={i18n.t('searchFilterBar.amountPaid.title')}
            hidden={Tools.isBlank(filterParams.minTotalAmountPaid) && Tools.isBlank(filterParams.maxTotalAmountPaid)}
            resetHandler={() =>
              resetParamsHandler([FilterParamsEnum.MinTotalAmountPaid, FilterParamsEnum.MaxTotalAmountPaid])
            }
          >
            <Space>
              <Form.Item label={<Typography className={'m-0'}>{i18n.t('searchFilterBar.from')}</Typography>}>
                <Input
                  type={'number'}
                  min={0}
                  placeholder={'0'}
                  value={parseInt(filterParams?.minTotalAmountPaid)}
                  onChange={(e) => setFilterParams({ ...filterParams, minTotalAmountPaid: e.target.value })}
                />
              </Form.Item>
              <Form.Item label={<Typography className={'m-0'}>{i18n.t('searchFilterBar.to')}</Typography>}>
                <Input
                  type={'number'}
                  min={0}
                  placeholder={'1000'}
                  value={parseInt(filterParams?.maxTotalAmountPaid)}
                  onChange={(e) => setFilterParams({ ...filterParams, maxTotalAmountPaid: e.target.value })}
                />
              </Form.Item>
            </Space>
          </FilterSection>
        )}
      </Form>
    </Drawer>
  );
};
