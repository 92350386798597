import { RefundRequestStatusesEnum } from '@app/types';

export const IN_PROCESS_STATUSES = [
  RefundRequestStatusesEnum.ApprovedByAdmin,
  RefundRequestStatusesEnum.ApprovedByAuditor,
  RefundRequestStatusesEnum.ApprovedByPlanningTeam,
  RefundRequestStatusesEnum.ReturnedForVerification,
  RefundRequestStatusesEnum.InProcess,
  RefundRequestStatusesEnum.WaitingForApprovalByAuditor,
  RefundRequestStatusesEnum.RefundReadyForPayment,
];

export const IN_PAID_STATUSES = [
  RefundRequestStatusesEnum.RefundPaid,
  RefundRequestStatusesEnum.ApprovedByPlanningTeam,
  RefundRequestStatusesEnum.WaitingForApprovalByRefundTeam,
  RefundRequestStatusesEnum.ApprovedByRefundTeam,
];

export const AUDIT_FA_STATUSES = [
  RefundRequestStatusesEnum.WaitingForApprovalByAuditor,
  RefundRequestStatusesEnum.ApprovedByAuditor,
  RefundRequestStatusesEnum.ReturnedForVerification,
];

export const PLANNING_FA_STATUSES = [
  RefundRequestStatusesEnum.RefundPaid,
  RefundRequestStatusesEnum.ApprovedByPlanningTeam,
];

export const REFUND_FA_STATUSES = [
  RefundRequestStatusesEnum.WaitingForApprovalByRefundTeam,
  RefundRequestStatusesEnum.ApprovedByRefundTeam,
  RefundRequestStatusesEnum.Done,
];
