import React, { FC, useRef } from 'react';
import { Modal, Typography } from 'antd';
import { ModalProps } from 'antd/es/modal';

import { DynamicForm } from '@app/components/dynamic_form';
import { RQContent } from '@app/components/rq_content';
import { i18n } from '@app/translations/i18n.config';
import { Tools } from '@app/utils/tools';
import { RQStatus } from '@app/components/rq_content/rq-content';
import { RequestStatusType } from '@app/types';

interface InvoiceModalProps extends ModalProps {
  status: RQStatus[] | RQStatus;
  data: any;
  onReceiptSubmitted?: (data: any, isEdit: boolean) => void;
  receiptValues?: any;
  requestStatus: RequestStatusType;
}

export const ReceiptModal: FC<InvoiceModalProps> = ({
  status,
  data,
  onReceiptSubmitted,
  receiptValues,
  requestStatus,
  ...otherProps
}) => {
  const formRef = useRef(null);

  const handleCancel = () => {
    if (formRef.current) {
      formRef.current.resetForm();
    }
    if (otherProps.onCancel) {
      otherProps.onCancel(null);
    }
  };

  return (
    <Modal
      {...otherProps}
      width={450}
      onCancel={handleCancel}
      title={
        <Typography.Title level={3}>
          {receiptValues ? i18n.t('invoices.edit') : i18n.t('invoices.new')}
        </Typography.Title>
      }
    >
      <RQContent status={status}>
        <DynamicForm
          ref={formRef}
          key={data?.id}
          onSubmit={(receipt) => onReceiptSubmitted(receipt, Tools.isPresent(receiptValues))}
          template={data?.template?.properties?.receipts?.items}
          hideModal={() => otherProps.onCancel(null)}
          receiptValues={receiptValues}
          requestStatus={requestStatus}
        />
      </RQContent>
    </Modal>
  );
};
