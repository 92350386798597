import { RefundRequestStatusesEnum } from '@app/types/refund-request-statuses.enum';

export enum HistoryEventTypesEnum {
  RefundCreated = 'refund_created',
  ReceiptsImported = 'receipts_imported',
  RefundUpdated = 'refund_updated',
  RefundDeleted = 'refund_deleted',
  RefundPaymentCreated = 'refund_payment_created',
  RefundStatusTransition = 'refund_status_transition',
  RefundAttachmentCreated = 'refund_attachment_created',
  RefundAttachmentDeleted = 'refund_attachment_deleted',
}

export type HistoryItemType = {
  id: string;
  eventType: HistoryEventTypesEnum;
  userId: string;
  userName: string;
  userRoles: string[];
  status: RefundRequestStatusesEnum;
  createdAt: string;
};
