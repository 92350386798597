import { HistoryEventTypesEnum } from '@app/types/history';
import { i18n } from '@app/translations/i18n.config';
import { DATE_FORMAT, EMPTY_VALUE_REPRESENTATION } from '@app/config/constants';
import dayjs from 'dayjs';
import { Tools } from '@app/utils/tools';

export const getHistoryTitle = (eventType: HistoryEventTypesEnum) => {
  const key = Tools.snakeToCamel(eventType);

  return Tools.isPresent(eventType) && Tools.haveValue(HistoryEventTypesEnum, eventType)
    ? i18n.t(`updatesHistory.historyTitles.${key}`)
    : EMPTY_VALUE_REPRESENTATION;
};

export const formatDateForDivider = (date: string): string => {
  if (dayjs(date).isSame(dayjs(), 'day')) {
    return i18n.t('updatesHistory.today');
  }
  if (dayjs(date).isSame(dayjs().subtract(1, 'day'), 'day')) {
    return i18n.t('updatesHistory.yesterday');
  }
  return dayjs(date).format(DATE_FORMAT);
};
