import React, { FC, useState } from 'react';
import { Button } from 'antd';
import { i18n } from '@app/translations/i18n.config';
import { HistoryDrawer } from '@app/components/updates_label/updates_history/history-drawer';

interface UpdatesHistoryProps {
  requestId: string;
}

export const UpdatesHistory: FC<UpdatesHistoryProps> = ({ requestId }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <>
      <Button size={'small'} type={'link'} onClick={() => setIsDrawerOpen(true)}>
        {i18n.t('updatesHistory.button')}
      </Button>
      <HistoryDrawer
        key={`${isDrawerOpen}`}
        requestId={requestId}
        isDrawerOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      />
    </>
  );
};
