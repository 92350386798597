import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  AutoComplete,
  Badge,
  Button,
  Card,
  Empty,
  Input,
  Popconfirm,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
  Upload,
} from 'antd';
import { NumericFormat } from 'react-number-format';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import Checkbox from 'antd/es/checkbox/Checkbox';

import { i18n } from '@app/translations/i18n.config';
import { RQContent } from '@app/components/rq_content';
import { ReceiptModal } from '@app/components/modals/receipt_modal';
import { Tools } from '@app/utils/tools';
import {
  changeVerificationsReceipts,
  getReceiptCategories,
  getReceiptsVerifications,
  getSelectedReceipts,
  getTemplate,
  importReceipts,
} from '@app/api';
import {
  isEditReceiptCategoryAllowed,
  isShowDownloadReceiptCheckboxes,
  isShowReceiptCategory,
  isShowVerifyReceiptCheckboxes,
} from '@app/utils/permissions/permissions';
import { useAuth } from '@app/auth/auth-context';
import { RequestStatusType, StatusCategoryEnum } from '@app/types';
import { EMPTY_VALUE_REPRESENTATION, IMPORT_FILES_ACCEPT_STRING } from '@app/config/constants';

import { ReactComponent as PlusIcon } from '@app/assets/icons/plus.svg';
import { ReactComponent as SearchIcon } from '@app/assets/icons/search.svg';
import { ReactComponent as DeleteIcon } from '@app/assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '@app/assets/icons/edit.svg';
import { ReactComponent as DownloadIcon } from '@app/assets/icons/download.svg';
import { Logger } from '@app/utils/logger/logger-service';

interface ReceiptsTableProps {
  onChange: (receipts: any) => void;
  data: Array<any>;
  isDataLoading?: boolean;
  isActionsAllowed?: boolean;
  isActionsForAuditorAllowed?: boolean;
  totalTax?: number;
  totalAmount?: number;
  totalTaxedAmount?: number;
  requestTitle?: string;
  requestStatus: RequestStatusType;
}

export const ReceiptsTable: FC<ReceiptsTableProps> = ({
  onChange,
  data,
  isDataLoading,
  isActionsAllowed,
  isActionsForAuditorAllowed,
  totalTax,
  totalAmount,
  totalTaxedAmount,
  requestTitle,
  requestStatus,
}) => {
  const { id } = useParams();
  const { userRoles } = useAuth();
  const queryClient = useQueryClient();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editReceiptId, setEditReceiptId] = useState(null);
  const [selectedReceipts, setSelectedReceipts] = useState([]);

  const { status: templateStatus, data: templateData } = useQuery({
    queryKey: ['template'],
    queryFn: () => getTemplate(),
  });

  const {
    data: verificationsData,
    isLoading: isVerificationsLoading,
    refetch: refetchVerifications,
  } = useQuery({
    queryKey: ['verifications', id],
    queryFn: () => getReceiptsVerifications(id),
  });

  const { mutate: verificationsMutate, isLoading: isVerificationsMutateLoading } = useMutation({
    mutationFn: (data: any) => changeVerificationsReceipts(id, data),
    onSuccess: async () => refetchVerifications(),
  });

  const { mutate: downloadAllReceiptsMutate, isLoading: isDownloadLoading } = useMutation({
    mutationFn: () => getSelectedReceipts(id, selectedReceipts.join()),
    onSuccess: (data) => {
      const BOM = '\uFEFF';
      const blob = new Blob([BOM + data], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = requestTitle;
      link.click();
      URL.revokeObjectURL(url);
    },
  });

  const { mutate: importReceiptsMutate, isLoading: isImportReceiptsMutateLoading } = useMutation({
    mutationFn: (data: any) => importReceipts(id, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries('refund_request');
    },
  });

  const {
    data: fetchReceiptCategories,
    isLoading: isFetchReceiptCategories,
    refetch: reFetchReceiptCategories,
  } = useQuery({
    queryKey: ['receipt_categories'],
    queryFn: async () => await getReceiptCategories(),
  });

  const categoryOptions = (Tools.isPresent(fetchReceiptCategories) ? fetchReceiptCategories : []).map((c) => ({
    label: `(${c.key}) ${c.name}`,
    value: c.key,
  }));

  const getCategoryName = (value: string) => {
    const label = categoryOptions?.find((c) => `${c.value}` === `${value}`)?.label || '';

    if (Tools.isPresent(value) && Tools.isPresent(label)) {
      return label;
    } else {
      return EMPTY_VALUE_REPRESENTATION;
    }
  };

  const handleDelete = (item) => {
    const updatedInvoices = data.filter((invoice) => invoice !== item);
    onChange(updatedInvoices);
  };

  const handleSelectAll = () => {
    if (selectedReceipts?.length === data?.length) {
      setSelectedReceipts([]);
    } else {
      setSelectedReceipts(data?.map((d) => d.id));
    }
  };

  const handleSelect = (id: string) => {
    if (selectedReceipts.some((el) => el === id)) {
      setSelectedReceipts(selectedReceipts.filter((el) => el !== id));
    } else {
      setSelectedReceipts([...selectedReceipts, id]);
    }
  };

  const handleVerify = (id: string) => {
    const exists = verificationsData?.some((item) => item.id === id);

    const updatedData = exists ? verificationsData?.filter((item) => item.id !== id) : [...verificationsData, { id }];

    verificationsMutate(updatedData);
  };

  const columns = [
    {
      width: 5,
      render: (record) => data.indexOf(record) + 1,
    },
    {
      title: i18n.t('invoices.tableTitle.invoice'),
      dataIndex: 'receiptNumber',
      key: 'receiptNumber',
      render: (text) => (text ? text : EMPTY_VALUE_REPRESENTATION),
    },
    {
      title: i18n.t('invoices.tableTitle.company'),
      dataIndex: 'company',
      key: 'company',
      render: (text) => (text ? text : EMPTY_VALUE_REPRESENTATION),
    },
    {
      title: <Badge dot={true} status="default" text={i18n.t('invoices.tableTitle.amount')} />,
      dataIndex: 'amount',
      key: 'amount',
      render: (text) => (text ? text : EMPTY_VALUE_REPRESENTATION),
    },
    {
      title: <Badge dot={true} status="warning" text={i18n.t('invoices.tableTitle.tax')} />,
      dataIndex: 'tax',
      key: 'tax',
      render: (text) => (text ? text : EMPTY_VALUE_REPRESENTATION),
    },
    {
      title: <Badge dot={true} status="success" text={i18n.t('invoices.tableTitle.totalAmount')} />,
      dataIndex: 'taxedAmount',
      key: 'taxedAmount',
      render: (text) => (text ? text : EMPTY_VALUE_REPRESENTATION),
    },
    {
      title: i18n.t('invoices.tableTitle.purchaseDescription'),
      dataIndex: 'description',
      key: 'description',
      render: (text) => (text ? text : EMPTY_VALUE_REPRESENTATION),
    },
    ...(isShowReceiptCategory(userRoles, requestStatus)
      ? [
          {
            title: i18n.t('invoices.tableTitle.category'),
            dataIndex: 'type',
            key: 'type',
            render: (text, record) => {
              return (
                <>
                  {isEditReceiptCategoryAllowed(userRoles, requestStatus) ? (
                    <AutoComplete
                      options={categoryOptions}
                      value={Tools.isBlank(text) ? '' : text}
                      filterOption={(inputValue, option) =>
                        `${option.label}`.includes(inputValue) || `${option.key}`.includes(inputValue)
                      }
                      onChange={(value) => {
                        const updatedRecord = { ...record, type: value };
                        submitReceiptHandler(updatedRecord, true);
                      }}
                    >
                      <Input placeholder={i18n.t('invoices.tableTitle.categoryPlaceholder')} />
                    </AutoComplete>
                  ) : (
                    getCategoryName(text)
                  )}
                </>
              );
            },
          },
        ]
      : []),
    {
      width: 70,
      render: (record) =>
        isActionsAllowed && (
          <Space size={'large'} className={'d-flex justify-content-end'}>
            {record?.id && (
              <Button
                type={'dashed'}
                size={'small'}
                className={'collapsed'}
                onClick={() => {
                  setEditReceiptId(record?.id);
                  setIsModalOpen(true);
                }}
                icon={<EditIcon />}
              />
            )}
            <Popconfirm
              title={
                <>
                  <Typography.Title level={2}>{i18n.t('popconfirm.deleteInvoiceTitle')}</Typography.Title>
                  <p>{i18n.t('popconfirm.deleteInvoiceDescription')}</p>
                </>
              }
              icon={null}
              okText={i18n.t('popconfirm.yes')}
              cancelText={i18n.t('popconfirm.no')}
              placement={'top'}
              onConfirm={() => handleDelete(record)}
              okButtonProps={{ size: 'large', type: 'ghost' }}
              cancelButtonProps={{ size: 'large', type: 'text' }}
            >
              <Button
                type={'dashed'}
                size={'small'}
                className={'collapsed'}
                icon={<DeleteIcon width={20} height={20} />}
              />
            </Popconfirm>
          </Space>
        ),
    },
    ...(isShowDownloadReceiptCheckboxes(userRoles)
      ? [
          {
            title: (
              <div className={'d-flex align-items-center'}>
                <Checkbox checked={selectedReceipts?.length === data?.length} onChange={() => handleSelectAll()} />

                <Tooltip title={i18n.t('buttons.downloadReceipts')}>
                  <Button
                    className={'mx-2'}
                    icon={Tools.isPresent(selectedReceipts) && <DownloadIcon width={17} />}
                    size={'small'}
                    type={'default'}
                    disabled={Tools.isBlank(selectedReceipts)}
                    loading={isDownloadLoading}
                    onClick={() => downloadAllReceiptsMutate()}
                  >
                    {selectedReceipts.length === data?.length
                      ? i18n.t('buttons.downloadAll')
                      : `${selectedReceipts.length}`}
                  </Button>
                </Tooltip>
              </div>
            ),
            width: 10,
            render: (text, record) => (
              <Checkbox
                onChange={() => handleSelect(record.id)}
                checked={selectedReceipts.find((el) => el === record.id)}
              />
            ),
          },
        ]
      : []),
    ...(isShowVerifyReceiptCheckboxes(userRoles, requestStatus?.key)
      ? [
          {
            width: 5,
            render: (text, record) => (
              <Tooltip
                placement={'bottom'}
                title={
                  verificationsData?.find((el) => el?.id === record.id)
                    ? i18n.t('invoices.verified')
                    : i18n.t('invoices.verify')
                }
              >
                <Checkbox
                  className={'green'}
                  onChange={() => handleVerify(record.id)}
                  checked={verificationsData?.find((el) => el?.id === record.id)}
                />
              </Tooltip>
            ),
          },
        ]
      : []),
  ];

  const submitReceiptHandler = (receipt, isEdit) => {
    const updatedData = isEdit ? data.map((item) => (item.id === receipt.id ? receipt : item)) : [...data, receipt];

    onChange(updatedData);
  };

  const uploadHandler = (options) => {
    const { file } = options;
    const formData = new FormData();
    formData.append('file', file);
    importReceiptsMutate(formData);
  };

  useEffect(() => {
    if (!isModalOpen) {
      setEditReceiptId(null);
    }
  }, [isModalOpen]);

  const fetchData = useCallback(async () => {
    await reFetchReceiptCategories();
  }, [id]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Card
      bordered={false}
      title={
        <Space size={null} className={'w-100 justify-content-between'}>
          <Typography.Title level={3}>{i18n.t('invoices.title')}</Typography.Title>
          {Tools.isPresent(data) && (
            <Space>
              <Badge
                className={'inline default'}
                count={<NumericFormat displayType={'text'} value={totalAmount} thousandSeparator="," />}
                text={i18n.t('invoices.tableTitle.amount')}
              />
              <Badge
                className={'inline warning'}
                count={<NumericFormat displayType={'text'} value={totalTax} thousandSeparator="," />}
                showZero
                text={i18n.t('invoices.tableTitle.tax')}
              />
              <Badge
                className={'inline success'}
                count={<NumericFormat displayType={'text'} value={totalTaxedAmount} thousandSeparator="," />}
                text={i18n.t('invoices.tableTitle.totalAmount')}
              />
            </Space>
          )}
          <Space>
            {isActionsAllowed && (
              <>
                <a href={'/receipts-import-template.xlsx'} target={'_blank'} rel="noreferrer">
                  <Button size={'small'} type={'link'}>
                    {i18n.t('invoices.downloadTemplate')}
                  </Button>
                </a>
                <Upload
                  disabled={isImportReceiptsMutateLoading}
                  accept={IMPORT_FILES_ACCEPT_STRING}
                  showUploadList={false}
                  customRequest={uploadHandler}
                >
                  <Tooltip title={i18n.t('invoices.importInfo')}>
                    <Button size={'small'} type={'default'} loading={isImportReceiptsMutateLoading}>
                      {i18n.t('invoices.import')}
                    </Button>
                  </Tooltip>
                </Upload>

                <Button icon={<PlusIcon />} size={'small'} type={'default'} onClick={() => setIsModalOpen(true)}>
                  {i18n.t('invoices.new')}
                </Button>
              </>
            )}
          </Space>
        </Space>
      }
    >
      {Tools.isBlank(data) ? (
        <Space className={'w-100 justify-content-center p-5'}>
          <Empty
            className={'light-grey-bg p-5'}
            image={<SearchIcon />}
            description={
              isActionsAllowed && (
                <>
                  <p>{i18n.t('invoices.emptyText')}</p>
                  <Button
                    className={'m-auto'}
                    icon={<PlusIcon />}
                    size={'small'}
                    type={'default'}
                    onClick={() => setIsModalOpen(true)}
                  >
                    {i18n.t('invoices.new')}
                  </Button>
                </>
              )
            }
          />
        </Space>
      ) : (
        <RQContent status={'success'}>
          <Table
            loading={isDataLoading || isVerificationsLoading || isVerificationsMutateLoading}
            pagination={{ size: 'small', hideOnSinglePage: true, defaultPageSize: 5 }}
            dataSource={data}
            columns={columns}
          />
        </RQContent>
      )}
      <ReceiptModal
        data={templateData}
        status={templateStatus}
        onReceiptSubmitted={submitReceiptHandler}
        open={isModalOpen}
        footer={false}
        onCancel={() => setIsModalOpen(false)}
        receiptValues={editReceiptId ? data.find((r) => r?.id === editReceiptId) : null}
        requestStatus={requestStatus}
      />
    </Card>
  );
};
