import { useLocation, useNavigate } from 'react-router-dom';
import { useMemo, useCallback } from 'react';
import { Tools } from '@app/utils/tools';

export function useQueryState<T extends Record<string, any>>() {
  const location = useLocation();
  const navigate = useNavigate();

  const query = useMemo(() => {
    const params = new URLSearchParams(location.search);
    const result: Record<string, string | string[] | undefined> = {};
    params.forEach((value, key) => {
      const valuesArray = value.split(',');
      result[key] = valuesArray.length > 1 ? valuesArray : value;
    });
    return result as T;
  }, [location.search]);

  const setQuery = useCallback(
    (newQuery: Partial<T>, replace = false) => {
      const currentParams = new URLSearchParams(location.search);

      Object.entries(newQuery).forEach(([key, value]) => {
        if (Tools.isBlank(value)) {
          currentParams.delete(key);
        } else if (Array.isArray(value)) {
          currentParams.set(key, value.join(','));
        } else {
          currentParams.set(key, String(value));
        }
      });

      const newSearch = currentParams.toString();
      const newUrl = `${location.pathname}${newSearch ? `?${newSearch}` : ''}`;

      replace ? navigate(newUrl, { replace: true }) : navigate(newUrl);
    },
    [location.pathname, location.search, navigate],
  );

  return [query, setQuery] as const;
}
