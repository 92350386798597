export enum EmployeeRequestStatus {
  InProgress = 'InProgress',
  Paid = 'Paid',
  Other = 'Other',
}

export enum FilterGroupStatus {
  InProgress = 'InProgressAll',
  Paid = 'PaidAll',
}
