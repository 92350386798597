import React, { FC } from 'react';
import { Tag } from 'antd';

import { RequestStatusType } from '@app/types/statuses';
import { useAuth } from '@app/auth/auth-context';
import { UserRolesEnum } from '@app/types';
import { Tools } from '@app/utils/tools';
import { IN_PAID_STATUSES, IN_PROCESS_STATUSES } from '@app/components/request_status/constants';
import { EmployeeRequestStatus } from '@app/components/request_status/enums';
import { getStatusColor, getStatusName } from '@app/components/request_status/utils';

interface RequestStatusProps {
  status: RequestStatusType;
  isToastMode?: boolean;
}

export const RequestStatus: FC<RequestStatusProps> = ({ status, isToastMode }) => {
  const { userRoles } = useAuth();
  const isRoleUser = userRoles?.includes(UserRolesEnum.User);

  if (Tools.isBlank(userRoles) && !isToastMode) return;

  const employeeUserStatuses = (() => {
    if (isRoleUser) {
      if (IN_PROCESS_STATUSES.includes(status.key)) return EmployeeRequestStatus.InProgress;
      if (IN_PAID_STATUSES.includes(status.key)) return EmployeeRequestStatus.Paid;
    }
    return EmployeeRequestStatus.Other;
  })();

  return (
    <Tag
      color={getStatusColor(status.key, status.statusCategory, employeeUserStatuses)}
      style={{ textTransform: 'uppercase' }}
    >
      {getStatusName(status.name, employeeUserStatuses)}
    </Tag>
  );
};
