import React, { FC, useEffect, useState } from 'react';
import { Modal, Radio, Space, Tag, Typography } from 'antd';
import { ModalProps } from 'antd/es/modal';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { NumericFormat } from 'react-number-format';

import { i18n } from '@app/translations/i18n.config';
import { getSafeboxes } from '@app/api/safeboxes';
import { RQContent } from '@app/components/rq_content';
import { RefundForm } from '@app/components/modals/refund_modal/refund-form';
import { addPayment, getPaymentsNew } from '@app/api/payments';
import { routes } from '@app/router/routes';
import { Tools } from '@app/utils/tools';
import { formatSafeboxState } from '@app/utils/calculations/calculations';
import { CommentForm } from '@app/components/modals/refund_modal/comment-form';
import { CommentPayload } from '@app/types/comments';
import { minTotalToShowMessage } from '@app/components/tables/safebox_table/constants';

interface RefundModalProps extends ModalProps {
  request: any;
}

export const RefundModal: FC<RefundModalProps> = ({ request, ...otherProps }) => {
  const navigate = useNavigate();

  const [currentSafebox, setCurrentSafebox] = useState(null);
  const [isSecondStep, setIsSecondStep] = useState(false);
  const [paymentData, setPaymentData] = useState(null);

  const { status: safeboxesStatus, data: safeboxes } = useQuery({
    queryKey: ['safeboxes'],
    queryFn: () => getSafeboxes(),
  });

  const { status: paymentStatus, data: payment } = useQuery({
    queryKey: [request.id, currentSafebox],
    queryFn: () => currentSafebox && getPaymentsNew(request.id, currentSafebox?.id),
  });

  const { mutate: addPaymentMutate, isLoading: isAddPaymentLoading } = useMutation({
    mutationFn: (data: string) => addPayment(request.id, data),
    onSuccess: async () => {
      setIsSecondStep(false);
      navigate(routes.dashboard.path);
      toast.success(i18n.t('messages.success.requestPaid'));
    },
    onError: () => {
      setCurrentSafebox(null);
      otherProps.onCancel(null);
    },
  });

  const addPaymentHandler = (comment: CommentPayload) => {
    const payload = {
      ...paymentData,
      comment,
    };
    addPaymentMutate(payload);
  };

  useEffect(() => {
    if (otherProps.open) {
      setCurrentSafebox(null);
      setIsSecondStep(false);
    }
  }, [otherProps.open]);

  return (
    <Modal
      {...otherProps}
      footer={false}
      centered
      width={680}
      maskClosable={false}
      title={<Typography.Title level={3}>{i18n.t('refund.title')}</Typography.Title>}
    >
      <RQContent status={safeboxesStatus}>
        <>
          {isSecondStep && (
            <>
              <Typography className={'fs-4 m-0 d-flex justify-content-center'}>
                <Typography className={'mb-0 text-grey mx-1'}>{i18n.t('refund.totalRefund')}</Typography>
                <NumericFormat displayType={'text'} value={paymentData?.amount} thousandSeparator="," />
                <Typography className={'fs-6 mb-0 text-grey mt-1 mx-1'}>د.إ</Typography>
              </Typography>
              <CommentForm
                isAddPaymentLoading={isAddPaymentLoading}
                onBack={() => setIsSecondStep(false)}
                onCommentAdded={addPaymentHandler}
              />
            </>
          )}
          <div className={isSecondStep ? 'd-none' : 'd-block'}>
            <Typography className={'text-dark-violet fs-6 mb-3'} style={{ marginTop: '-25px' }}>
              {i18n.t('refund.description')}
            </Typography>
            <Radio.Group
              className={'w-100 mb-3'}
              value={currentSafebox?.id}
              onChange={(e) => {
                setCurrentSafebox(safeboxes.find((s) => s.id === e?.target?.value));
              }}
            >
              {safeboxes?.map((s) => (
                <Radio
                  className={'w-100'}
                  key={s.id}
                  value={s.id}
                  disabled={s.totalAmount < request?.request?.totalAmount}
                >
                  <p className={'m-0'}>{s.name}</p>
                  <Space className={'w-100 d-flex justify-content-center m-1'}>
                    {s.totalAmount < minTotalToShowMessage && (
                      <Tag color={'gold'}>
                        {i18n.t('refund.lessTotal')}
                        <NumericFormat displayType={'text'} value={minTotalToShowMessage} thousandSeparator={','} />
                      </Tag>
                    )}
                    <p className={'m-0'}>
                      <NumericFormat displayType={'text'} value={s.totalAmount} thousandSeparator="," /> د.إ
                    </p>
                  </Space>
                  {s.totalAmount < request?.request?.totalAmount && (
                    <Tag className={'m-0'} color={'volcano'}>
                      {i18n.t('refund.notEnough')}
                    </Tag>
                  )}
                </Radio>
              ))}
            </Radio.Group>
            {currentSafebox && !isAddPaymentLoading && (
              <RefundForm
                paymentStatus={paymentStatus}
                payment={payment}
                safebox={currentSafebox}
                onPaymentAdd={(data) => {
                  setPaymentData(data);
                  setIsSecondStep(true);
                }}
                data={Tools.isPresent(currentSafebox?.state) ? formatSafeboxState(currentSafebox?.state) : []}
              />
            )}
          </div>
        </>
      </RQContent>
    </Modal>
  );
};
