import axiosInstance from '@app/config/api/config';

export async function getHistoryLast(requestId: string) {
  const res = await axiosInstance.get(`refund_requests/${encodeURIComponent(requestId)}/history/last`);
  return res.data;
}

export async function getHistory(requestId: string) {
  const res = await axiosInstance.get(`refund_requests/${encodeURIComponent(requestId)}/history`);
  return res.data;
}
