import { Hashmap } from '@app/types/hashmap';

export class Tools {
  static isPresent(value: any | string | object): boolean {
    if (typeof value === 'undefined') {
      return false;
    }

    if (typeof value === 'boolean') {
      return true;
    }

    if (typeof value === 'string') {
      return value.trim().length > 0;
    }

    if (typeof value === 'number') {
      return true;
    }

    if (Array.isArray(value)) {
      return value.length > 0;
    }

    return value !== null && value !== undefined && Object.getOwnPropertyNames(value).length > 0;
  }

  static isBlank(value: any | string | object) {
    if (typeof value === 'string') {
      return value.trim().length === 0;
    }

    if (typeof value === 'number') {
      return false;
    }

    if (typeof value === 'boolean') {
      return false;
    }

    if (Array.isArray(value)) {
      return value.length === 0;
    }

    return value === null || value === undefined || Object.getOwnPropertyNames(value).length === 0;
  }

  static splitByChunks(data: any[], perChunk: number = 3) {
    return data.reduce((resultArray: any, item: any, index: number) => {
      const chunkIndex = Math.floor(index / perChunk);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);
  }

  static groupBy(xs: any[], key: string) {
    return xs.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  static uniqueByKey(data: any[], key: string) {
    return [...new Map(data.map((item) => [item[key], item])).values()];
  }

  static arrayToObject<T>(data: Array<any>, key: number | string) {
    const result: Hashmap<T> = {};

    data.forEach((gr) => {
      result[gr[key]] = gr;
    });

    return result;
  }

  static arrayToItselfObject<T>(data: Array<any>) {
    const result: Hashmap<T> = {};

    data.forEach((gr) => {
      result[gr] = gr;
    });

    return result;
  }

  static objectToArray(data: any) {
    return Object.keys((key: any) => data[key] as Array<any>);
  }

  static hash(data: string, len: number) {
    let hash = data.split('').reduce((hash, char) => {
      return char.charCodeAt(0) + (hash << 6) + (hash << 16) - hash;
    }, 0);

    return `${hash}`;
  }

  static compactObject(data: any) {
    const result = {};

    Object.keys(data).forEach((key) => {
      if (this.isPresent(data[key]) && data[key] !== 'null') {
        // @ts-ignore
        result[key] = data[key];
      }
    });

    return result;
  }

  static compactArray(data: any[]) {
    return data.filter((el: any) => Tools.isPresent(el));
  }

  static timeout(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  static humanize(str: string) {
    return str.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/\b\w/g, (char) => char.toUpperCase());
  }

  static isDigit(value: string) {
    return /^\d*$/.test(value);
  }

  static wrapArray(value: string) {
    return [].concat.apply([], Array.of(value)).filter((n) => n);
  }

  static snakeToCamel(str: string) {
    const regx = /([-_][a-z])/g;
    return str.toLowerCase().replace(regx, (group) => group.toUpperCase().replace('-', '').replace('_', ''));
  }

  static haveValue(target: object | null | unknown, value: any): boolean {
    return target !== null && target !== undefined && Object.values(target).includes(value);
  }
}
