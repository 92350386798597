import React, { FC, useState } from 'react';
import { Button, Popconfirm, Space, Table, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { i18n } from '@app/translations/i18n.config';
import { LanguageContext } from '@app/global_provider';
import { RequestTransitionCommands, DashboardTabs } from '@app/types';
import { useAuth } from '@app/auth/auth-context';

import {
  deleteRefundRequest,
  getCancelledRefundRequests,
  getDraftRefundRequests,
  getRefundRequests,
  submitRefundRequest,
} from '@app/api';

import { RQContent } from '@app/components/rq_content';
import { RequestStatus } from '@app/components/request_status';
import { Tools } from '@app/utils/tools';
import {
  isEditRequestActionsAllowed,
  isShowStartButton,
  isShowTotalAmountPaid,
} from '@app/utils/permissions/permissions';
import { DEFAULT_REQUESTS_PARAMS, EMPTY_VALUE_REPRESENTATION } from '@app/config/constants';
import { SearchFilterBar } from '@app/components/tables/request_table/search_filter_bar/search-filter-bar';
import { useQueryState } from '@app/hooks/use-query-state';
import { getExpandedStatuses } from '@app/components/tables/request_table/search_filter_bar/utils';

import { ReactComponent as EditIcon } from '@app/assets/icons/edit.svg';
import { ReactComponent as ViewIcon } from '@app/assets/icons/view_mode_icon.svg';
import { ReactComponent as DeleteIcon } from '@app/assets/icons/delete.svg';

interface RequestTableProps {
  currentTab: DashboardTabs;
}

export const RequestTable: FC<RequestTableProps> = ({ currentTab }) => {
  const { userRoles, user } = useAuth();
  const navigate = useNavigate();
  const { isRtl } = React.useContext<any>(LanguageContext);

  const [params, setParams] = useState(DEFAULT_REQUESTS_PARAMS);
  const [draftParams, setDraftParams] = useState(DEFAULT_REQUESTS_PARAMS);
  const [cancelledParams, setCancelledParams] = useState(DEFAULT_REQUESTS_PARAMS);
  const [urlParams] = useQueryState();

  const { status, data: requestsData } = useQuery({
    queryKey: ['refund_requests', params, urlParams],
    queryFn: () => {
      const { statuses, ...rest } = urlParams;

      return getRefundRequests({
        ...params,
        ...rest,
        statuses: getExpandedStatuses(statuses),
      });
    },
  });

  const {
    status: draftStatus,
    refetch: refetchDraftsRequests,
    data: draftsRequestsData,
  } = useQuery({
    queryKey: ['draft_refund_requests', draftParams],
    queryFn: () => getDraftRefundRequests(draftParams),
  });

  const {
    status: cancelStatus,
    refetch: _,
    data: cancelledRequestsData,
  } = useQuery({
    queryKey: ['cancelled_refund_requests', cancelledParams],
    queryFn: () => getCancelledRefundRequests(cancelledParams),
  });

  const { mutate: deleteRequestMutate, isLoading: isDeleteLoading } = useMutation({
    mutationFn: (data: string) => deleteRefundRequest(data),
    onSuccess: async () => {
      refetchDraftsRequests();
      toast.success(i18n.t('messages.success.deleteRequest'));
    },
  });

  const { mutate: startRequestMutate, isLoading: isStartLoading } = useMutation({
    mutationFn: (requestId: string) => submitRefundRequest(requestId, RequestTransitionCommands.Submit),
    onSuccess: async (data) => {
      navigate(`/request/${data?.id}`);
    },
  });

  const handleTableChange = (pagination, filters, sorter) => {
    let updatedParams = { ...DEFAULT_REQUESTS_PARAMS };
    if (currentTab === DashboardTabs.Drafts) {
      updatedParams = { ...draftParams };
    } else if (currentTab === DashboardTabs.Cancelled) {
      updatedParams = { ...cancelledParams };
    } else {
      updatedParams = { ...params };
    }

    if (pagination) {
      updatedParams.page = pagination.current;
      updatedParams.per_page = pagination.pageSize;
    }

    if (Tools.isPresent(sorter.order)) {
      const order = sorter.order === 'ascend' ? 'asc' : 'desc';
      updatedParams.sort = `${sorter.field}:${order}`;
    } else {
      updatedParams.sort = null;
    }

    if (currentTab === DashboardTabs.Drafts) {
      setDraftParams(updatedParams);
    } else if (currentTab === DashboardTabs.Cancelled) {
      setCancelledParams(updatedParams);
    } else {
      setParams(updatedParams);
    }
  };

  const getSortOrder = (key: string): 'ascend' | 'descend' | null => {
    let currentTabParams = { ...params };
    if (currentTab === DashboardTabs.Drafts) {
      currentTabParams = { ...draftParams };
    } else if (currentTab === DashboardTabs.Cancelled) {
      currentTabParams = { ...cancelledParams };
    } else {
      currentTabParams = { ...params };
    }

    if (currentTabParams?.sort?.startsWith(key)) {
      const [_, sortValue] = currentTabParams?.sort?.split(':');
      return sortValue === 'asc' ? 'ascend' : 'descend';
    } else {
      return null;
    }
  };

  const columns = [
    {
      title: i18n.t('request.tableTitle.requestId'),
      dataIndex: 'requestId',
      key: 'requestId',
      sortOrder: getSortOrder('requestId'),
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: i18n.t('request.tableTitle.status'),
      dataIndex: 'status',
      render: (record) => <RequestStatus status={record} />,
      sortOrder: getSortOrder('status'),
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: i18n.t('request.tableTitle.date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (record) => dayjs(record).format('MM/DD/YYYY'),
      sortOrder: getSortOrder('createdAt'),
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: i18n.t('request.tableTitle.fullAmount'),
      dataIndex: 'totalAmount',
      render: (record) => (Tools.isPresent(record) ? parseFloat(`${record}`).toFixed(2) : EMPTY_VALUE_REPRESENTATION),
      sortOrder: getSortOrder('totalAmount'),
      sorter: true,
      showSorterTooltip: false,
    },
    ...(isShowTotalAmountPaid(userRoles)
      ? [
          {
            title: i18n.t('request.tableTitle.totalAmountPaid'),
            dataIndex: 'totalAmountPaid',
            render: (record) =>
              Tools.isPresent(record) ? parseFloat(`${record}`).toFixed(2) : EMPTY_VALUE_REPRESENTATION,
            sortOrder: getSortOrder('totalAmountPaid'),
            //sorter: true,
            showSorterTooltip: false,
          },
        ]
      : []),
    {
      title: i18n.t('request.tableTitle.recipient'),
      dataIndex: 'recipientId',
      key: 'recipientId',
      sortOrder: getSortOrder('recipientId'),
      sorter: true,
      showSorterTooltip: false,
    },
    {
      width: 5,
      render: (record) => (
        <Space size={'large'}>
          {isShowStartButton(userRoles, record) && currentTab === DashboardTabs.Requests && (
            <Popconfirm
              title={
                <>
                  <p>{i18n.t('popconfirm.transitionsActionDescription', { event: i18n.t('popconfirm.start') })}</p>
                </>
              }
              onConfirm={() => startRequestMutate(record.id)}
              icon={null}
              okText={i18n.t('popconfirm.start')}
              cancelText={i18n.t('popconfirm.no')}
              placement={'bottomLeft'}
              okButtonProps={{ size: 'large', type: 'text' }}
              cancelButtonProps={{ size: 'large', type: 'ghost' }}
            >
              <Button type={'primary'} size={'small'}>
                {i18n.t('buttons.startTask')}
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
    {
      width: 5,
      render: (record) => (
        <Space size={'large'}>
          {currentTab === DashboardTabs.Drafts && (
            <Popconfirm
              title={
                <>
                  <Typography.Title level={2}>{i18n.t('popconfirm.deleteRequestTitle')}</Typography.Title>
                  <p>{i18n.t('popconfirm.deleteRequestDescription')}</p>
                </>
              }
              className={'danger'}
              icon={null}
              okText={i18n.t('popconfirm.yes')}
              cancelText={i18n.t('popconfirm.no')}
              placement={'right'}
              onConfirm={() => deleteRequestMutate(record.id)}
              okButtonProps={{ size: 'large', type: 'ghost' }}
              cancelButtonProps={{ size: 'large', type: 'text' }}
            >
              <Button
                type={'dashed'}
                size={'small'}
                className={'collapsed'}
                icon={<DeleteIcon width={20} height={20} />}
              />
            </Popconfirm>
          )}

          <Link className={'d-flex align-items-center'} to={`/request/${record.id}`}>
            {isEditRequestActionsAllowed(userRoles, record?.status, record?.creatorId === user.sub) ? (
              <Tooltip title={i18n.t('request.edit')}>
                <EditIcon />
              </Tooltip>
            ) : (
              <Tooltip title={i18n.t('request.view')}>
                <ViewIcon width={12} height={12} style={{ transform: isRtl ? 'none' : 'rotate(90deg)' }} />
              </Tooltip>
            )}
          </Link>
        </Space>
      ),
    },
  ];

  let currentRequestsData = {
    data: [],
    meta: {
      currentPage: 0,
      perPage: 10,
      total: 0,
    },
  };

  if (currentTab === DashboardTabs.Requests) {
    currentRequestsData = requestsData;
  } else if (currentTab === DashboardTabs.Drafts) {
    currentRequestsData = draftsRequestsData;
  } else if (currentTab === DashboardTabs.Cancelled) {
    currentRequestsData = cancelledRequestsData;
  }

  return (
    <>
      {currentTab === DashboardTabs.Requests && <SearchFilterBar />}
      <RQContent status={[status, draftStatus, cancelStatus]}>
        <Table
          loading={isDeleteLoading}
          dataSource={currentRequestsData?.data}
          pagination={{
            current: currentRequestsData?.meta?.currentPage,
            pageSize: currentRequestsData?.meta?.perPage,
            total: currentRequestsData?.meta?.total,
            size: 'small',
            showSizeChanger: true,
          }}
          onChange={handleTableChange}
          columns={columns}
        />
      </RQContent>
    </>
  );
};
