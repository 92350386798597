import { DefaultOptionType } from 'rc-select/lib/Select';
import {
  AUDIT_FA_STATUSES,
  IN_PAID_STATUSES,
  IN_PROCESS_STATUSES,
  PLANNING_FA_STATUSES,
  REFUND_FA_STATUSES,
} from '@app/components/request_status/constants';
import { i18n } from '@app/translations/i18n.config';
import { FilterGroupStatus } from '@app/components/request_status/enums';
import { Tools } from '@app/utils/tools';
import { UserRolesEnum } from '@app/types';

const getStatusOptionsForRoleUser = (options: DefaultOptionType) => {
  const uniqueStatuses = options.filter(
    (status, index, self) =>
      !IN_PROCESS_STATUSES.includes(status.value) &&
      !IN_PAID_STATUSES.includes(status.value) &&
      index === self.findIndex((s) => s.value === status.value),
  );

  const hasProcessStatuses = options.some((status) => IN_PROCESS_STATUSES.includes(status.value));
  const hasPaidStatuses = options.some((status) => IN_PAID_STATUSES.includes(status.value));

  if (hasProcessStatuses) {
    uniqueStatuses.push({
      label: i18n.t('request.inProcess'),
      value: FilterGroupStatus.InProgress,
    });
  }

  if (hasPaidStatuses) {
    uniqueStatuses.push({
      label: i18n.t('request.paid'),
      value: FilterGroupStatus.Paid,
    });
  }

  return uniqueStatuses;
};

const getStatusOptionsForRoleAudit = (options: DefaultOptionType) => {
  return options.filter((option) => AUDIT_FA_STATUSES.includes(option.value));
};

const getStatusOptionsForRolePlanning = (options: DefaultOptionType) => {
  return options.filter((option) => PLANNING_FA_STATUSES.includes(option.value));
};

const getStatusOptionsForRoleRefund = (options: DefaultOptionType) => {
  return options.filter((option) => REFUND_FA_STATUSES.includes(option.value));
};

export const getStatusOptionsByRole = (options: DefaultOptionType, userRoles: Array<string>) => {
  const isUser = userRoles.includes(UserRolesEnum.User);
  const isAuditor = userRoles.includes(UserRolesEnum.Auditor);
  const isPlanning = userRoles.includes(UserRolesEnum.Planning);
  const isRefundTeam = userRoles.includes(UserRolesEnum.RefundTeam);

  if (isUser) return getStatusOptionsForRoleUser(options);
  if (isAuditor) return getStatusOptionsForRoleAudit(options);
  if (isPlanning) return getStatusOptionsForRolePlanning(options);
  if (isRefundTeam) return getStatusOptionsForRoleRefund(options);

  return options;
};

export const getExpandedStatuses = (statuses) => {
  return Tools.wrapArray(statuses).flatMap((status) => {
    if (status === FilterGroupStatus.InProgress) {
      return IN_PROCESS_STATUSES;
    }
    if (status === FilterGroupStatus.Paid) {
      return IN_PAID_STATUSES;
    }
    return status;
  });
};
