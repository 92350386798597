import axiosInstance from '@app/config/api/config';

export async function getAttachments(requestId: string) {
  const res = await axiosInstance.get(`refund_requests/${requestId}/attachments`);
  return res.data;
}

export async function getAttachmentBlob(requestId: string, attachmentId: string) {
  const res = await axiosInstance.get(`refund_requests/${requestId}/attachments/${attachmentId}/blob`, {
    responseType: 'blob',
  });
  return res.data;
}

export async function getAllAttachmentBlob(requestId: string) {
  const res = await axiosInstance.get(`refund_requests/${requestId}/attachments/all_blob`, {
    responseType: 'blob',
  });
  return res.data;
}

export async function addAttachment(requestId: string, file: any) {
  const res = await axiosInstance.post(`refund_requests/${requestId}/attachments`, file);
  return res.data;
}

export async function deleteAttachment(requestId: string, attachmentId: string) {
  const res = await axiosInstance.delete(`refund_requests/${requestId}/attachments/${attachmentId}`);
  return res.data;
}
