import { RefundRequestStatusesEnum, StatusCategoryEnum } from '@app/types';
import { i18n } from '@app/translations/i18n.config';
import { EmployeeRequestStatus } from '@app/components/request_status/enums';

export const getStatusColor = (
  key: RefundRequestStatusesEnum,
  category: StatusCategoryEnum,
  userStatus: EmployeeRequestStatus,
) => {
  if (userStatus === EmployeeRequestStatus.InProgress || userStatus === EmployeeRequestStatus.Paid) return 'blue';
  if (key === RefundRequestStatusesEnum.Cancelled) return 'red';

  switch (category) {
    case StatusCategoryEnum.ToDo:
      return 'volcano';
    case StatusCategoryEnum.InProgress:
      return 'blue';
    case StatusCategoryEnum.Done:
      return 'green';
    default:
      return 'volcano';
  }
};

export const getStatusName = (statusName: string, userStatus: EmployeeRequestStatus) => {
  switch (userStatus) {
    case EmployeeRequestStatus.InProgress:
      return i18n.t('request.inProcess');
    case EmployeeRequestStatus.Paid:
      return i18n.t('request.paid');
    default:
      return statusName;
  }
};
