import React from 'react';
import { Card, Table, Typography } from 'antd';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';
import { NumericFormat } from 'react-number-format';

import { i18n } from '@app/translations/i18n.config';
import { RQContent } from '@app/components/rq_content';
import { EMPTY_VALUE_REPRESENTATION } from '@app/config/constants';
import { getRefundPayments } from '@app/api/payments';
import { Tools } from '@app/utils/tools';
import { coinDenomination } from '@app/components/tables/safebox_table/constants';

import { ReactComponent as MoneyIcon } from '@app/assets/icons/money.svg';
import { ReactComponent as CoinIcon } from '@app/assets/icons/coin.svg';

export const PaymentsTable = ({ refundId }) => {
  const { status: paymentsStatus, data: payments } = useQuery({
    queryKey: ['payments', refundId],
    queryFn: () => getRefundPayments(refundId),
  });

  const denominationsColumns = [
    {
      title: '',
      dataIndex: 'icon',
      key: 'icon',
    },
    {
      title: i18n.t('payment.denominationsTable.denomination'),
      dataIndex: 'denomination',
      key: 'denomination',
    },
    {
      title: i18n.t('payment.denominationsTable.quantity'),
      dataIndex: 'quantity',
      key: 'quantity',
    },
  ];

  const columns = [
    {
      width: 5,
      title: '#',
      render: (record) => (Tools.isPresent(payments) ? payments?.indexOf(record) + 1 : EMPTY_VALUE_REPRESENTATION),
    },
    {
      title: i18n.t('payment.tableTitle.whoPaid'),
      render: (record) => {
        return Tools.isPresent(record?.creator?.name) ? record?.creator?.name : EMPTY_VALUE_REPRESENTATION;
      },
    },
    {
      title: i18n.t('payment.tableTitle.amount'),
      dataIndex: 'amount',
      key: 'amount',
      render: (text) =>
        text ? <NumericFormat displayType={'text'} value={text} thousandSeparator="," /> : EMPTY_VALUE_REPRESENTATION,
    },
    {
      title: i18n.t('payment.tableTitle.date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (record) => dayjs(record).format('MM/DD/YYYY'),
    },
    {
      render: (record) => {
        const denominations = Object.keys(record.denominations)
          .filter((key) => record.denominations[key] > 0)
          .map((el) => parseFloat(el))
          .sort((a, b) => (a > b ? b : a))
          .reverse();

        const denominationsData = denominations.map((key) => {
          return {
            denomination: key,
            quantity: record.denominations[key],
            icon: coinDenomination.includes(key) ? <CoinIcon /> : <MoneyIcon />,
          };
        });

        return (
          <details>
            <summary>{i18n.t('payment.tableTitle.denominations')}</summary>
            <Table
              showHeader={true}
              pagination={false}
              rowClassName={'without-bg'}
              dataSource={denominationsData}
              columns={denominationsColumns}
            />
          </details>
        );
      },
    },
  ];

  return (
    <RQContent status={paymentsStatus}>
      {Tools.isPresent(payments) && (
        <Card bordered={false} title={<Typography.Title level={3}>{i18n.t('payment.info')}</Typography.Title>}>
          <Table
            rowClassName={'without-bg'}
            dataSource={payments}
            pagination={false}
            columns={columns}
            footer={(records) => {
              const totalAmount = records.reduce((sum, item) => sum + parseFloat(item.amount), 0.0);
              return (
                <Typography.Title level={3} style={{ minWidth: '33.33%', padding: '0 8px' }} className={'m-0'}>
                  {i18n.t('payment.totalPaid')}&nbsp;
                  <NumericFormat displayType={'text'} value={totalAmount} thousandSeparator="," />
                  <span className={'fs-6'}>د.إ</span>
                </Typography.Title>
              );
            }}
          />
        </Card>
      )}
    </RQContent>
  );
};
