import React, { FC } from 'react';
import dayjs from 'dayjs';
import { Space, Typography } from 'antd';
import { useQuery } from 'react-query';

import { i18n } from '@app/translations/i18n.config';
import { getHistoryLast } from '@app/api';
import { RQContent } from '@app/components/rq_content';
import { Tools } from '@app/utils/tools';
import { DATE_TIME_FORMAT, EMPTY_VALUE_REPRESENTATION } from '@app/config/constants';
import { UpdatesHistory } from '@app/components/updates_label/updates_history';
import { isShowUpdatesHistory } from '@app/utils/permissions/permissions';
import { useAuth } from '@app/auth/auth-context';

import { ReactComponent as ClockIcon } from '@app/assets/icons/clock_icon.svg';

interface UpdatesLabelProps {
  requestId?: string;
  updatedAt?: string;
  className?: string;
}

export const UpdatesLabel: FC<UpdatesLabelProps> = ({ requestId, updatedAt, className }) => {
  const { userRoles } = useAuth();

  const { status: historyStatus, data: historyData } = useQuery({
    queryKey: [requestId],
    queryFn: () => getHistoryLast(requestId),
  });

  return (
    <RQContent status={historyStatus}>
      <Space className={'w-100 align-items-center'}>
        {Tools.isPresent(historyData) && (
          <Typography.Title level={5} className={className}>
            <ClockIcon width={12} height={12} style={{ verticalAlign: 'sub', margin: '0 3px' }} />
            {i18n.t('request.updateLabel', {
              updateOwner: historyData?.userName ?? EMPTY_VALUE_REPRESENTATION,
              date: dayjs(updatedAt).format(DATE_TIME_FORMAT) ?? EMPTY_VALUE_REPRESENTATION,
              role: historyData?.userRoles ? Tools.humanize(historyData?.userRoles.join()) : null,
            })}
          </Typography.Title>
        )}
        {isShowUpdatesHistory(userRoles) && <UpdatesHistory requestId={requestId} />}
      </Space>
    </RQContent>
  );
};
