import React, { FC } from 'react';
import { Space, Tag, Typography } from 'antd';
import dayjs from 'dayjs';

import { Tools } from '@app/utils/tools';
import { HistoryItemType } from '@app/types/history';
import { TIME_FORMAT } from '@app/config/constants';
import { HistoryTitle } from '@app/components/updates_label/updates_history/history-title';
import { RequestStatusType } from '@app/types';
import { Hashmap } from '@app/types/hashmap';

interface HistoryItemProps {
  historyItemData: HistoryItemType;
  statusesHash: Hashmap<RequestStatusType>;
}

export const HistoryItem: FC<HistoryItemProps> = ({ historyItemData, statusesHash }) => {
  const status = statusesHash[historyItemData?.status];

  return (
    <Space.Compact
      className={'w-100 light-grey-bg border-radius-base justify-content-between p-2 mb-2'}
      style={{ top: '-0.5rem', overflow: 'visible', zIndex: 2 }}
    >
      <Space direction={'vertical'} className={'w-100'} size={'small'}>
        <Space className={'w-100 justify-content-between align-items-center'}>
          <HistoryTitle status={status} eventType={historyItemData.eventType} />
          <Typography.Title level={5}>{dayjs(historyItemData?.createdAt).format(TIME_FORMAT)}</Typography.Title>
        </Space>
        <Space className={'justify-content-between align-items-center'}>
          <Typography.Title level={4}>{historyItemData?.userName}</Typography.Title>
          {historyItemData?.userRoles.map((role) => (
            <Tag color={'gold'}>{Tools.humanize(role)}</Tag>
          ))}
        </Space>
      </Space>
    </Space.Compact>
  );
};
